"use client";

import React, { useMemo, useState } from "react";
import clsx from "classnames";
import { buildCloudflareURL } from "@utils/imageUtils";
import { motion } from "framer-motion";
import Text from "./Text";

const screens = {
  xxs: { max: "359px" }, // for super small screens
  xs: "360px",
  sm: "700px", // bigger than most phones
  md: "850px",
  lg: "1200px",
  xl: "1600px", // larger than 15" macbook pro
  xxl: "2000px",
};

const Image = ({
  image,
  className: _className,
  fit = "cover",
  eager,
  caption,
  sizes,
  ars,
  params,
  background = false,
  fill = false,
  fitHeight = false,
  imageClassName,
  draggable = true,
}) => {
  const [inView, setInView] = useState(eager);
  const [loaded, setLoaded] = useState(eager);
  const { url, alt, title, width, height, focalPoint } = image || {};
  const [fpx, fpy] = focalPoint || [false, false];

  const isSvg = url?.includes(".svg");

  const sources = useMemo(() => {
    let widthAdjust = Object.values(sizes || {})?.[0] || 1;
    // precalculate aspect ratios
    const cArs = Object.entries(ars || {}).reduce(
      (agg, [k, v]) => ({ ...agg, [k]: v[1] / v[0] }),
      {}
    );
    let ar = Object.values(cArs)[0] || height / width;

    return Object.entries(screens).reduce((s, screen) => {
      const [k, v] = screen;

      // filter xxs
      if (k !== "xxs" && url) {
        widthAdjust = sizes?.[k] || widthAdjust;
        ar = cArs[k] || ar;
        const w = parseInt(v.replace("px", "") * widthAdjust, 10);
        const h = parseInt(ar * w, 10);
        const baseParams = { url, w, h };

        const cfUrl1x = buildCloudflareURL({
          ...baseParams,
          q: 80,
          fit,
          g: focalPoint ? `${fpx}x${fpy}` : "center",
          ...params,
          dpr: 1.5,
        });
        const cfUrl2x = buildCloudflareURL({
          ...baseParams,
          q: 60,
          fit,
          g: focalPoint ? `${fpx}x${fpy}` : "center",
          ...params,
          dpr: 2.5,
        });
        return {
          ...s,
          // 1x dpr
          [`${k}1x`]: { url: cfUrl1x, size: k === "xs" ? 0 : v, dpr: 1 },
          // 2x dpr
          [`${k}2x`]: { url: cfUrl2x, size: k === "xs" ? 0 : v, dpr: 2 },
        };
      }
      return s;
    }, {});
  }, []);

  const styleObject = useMemo(() => {
    const object = {};
    if (!ars && !fill) {
      object.aspectRatio = (width / height).toFixed(2);
    }
    if (fitHeight) {
      object.height = "100%";
    }
    return object;
  }, []);

  if (!image || !url) return <div />;

  return (
    <motion.figure
      onViewportEnter={() => setInView(true)}
      viewport={{ margin: "25% 100%", once: true }}
      className={clsx(_className, "flex flex-col overflow-hidden", {
        "absolute inset-0": fill,
        relative: !fill,
        "h-full": fitHeight,
      })}
    >
      <picture
        className={clsx("relative w-full", imageClassName, {
          "bg-gray": background,
          "h-full": fill,
        })}
        style={styleObject}
        // style={fitHeight ? {  } : undefined}
      >
        {inView &&
          !isSvg &&
          Object.entries(sources)
            // since we're using min-width, reverse the direction
            .reverse()
            .map(([k, s]) => (
              <source
                key={k}
                srcSet={s.url}
                media={
                  s.dpr === 2
                    ? `(min-width: ${s.size}) and (min-resolution: 80dpi)`
                    : `(min-width: ${s.size})`
                }
              />
            ))}
        <motion.img
          // todo: clean this up
          // eslint-disable-next-line no-nested-ternary
          src={inView ? (isSvg ? url : sources.xs1x.url) : null}
          alt={alt || title}
          draggable={draggable}
          className={clsx(
            imageClassName,
            "h-full w-full transition duration-500",
            {
              "object-contain": !fill && fit !== "cover",
              "object-cover": fit === "cover",
              "absolute inset-0 h-full w-full object-cover": fill,
              "opacity-0": !loaded,
              relative: !fill,
            }
          )}
          // loading={!eager && lazy ? "lazy" : null}
          onLoad={() => {
            setLoaded(true);
          }}
          onViewportEnter={e => {
            if (e.target.complete) {
              setLoaded(true);
            }
          }}
        />
      </picture>
      {caption && (
        <figcaption
          className={clsx({ fill: "absolute bottom-3 right-0 z-10" })}
        >
          <Text
            tag="caption"
            className={clsx({ fill: "absolute bottom-3 right-0 z-10" })}
            html={caption}
          />
        </figcaption>
      )}
    </motion.figure>
  );
};

export default Image;
